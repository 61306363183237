import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <button className="faq-question" onClick={toggleOpen}>
        {question}
        <span>{isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
      </button>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    { question: 'Combien de temps faut-il pour scanner une propriété ?', answer: 'Le temps nécessaire pour scanner une propriété dépend de sa taille et de sa complexité. En général, un scan complet peut prendre entre 1 et 3 heures.' },
    { question: 'Qu’est-ce que Matterport pour l’immobilier ?', answer: 'Matterport est une solution de visite virtuelle en 3D qui permet aux acheteurs potentiels de visiter des propriétés à distance, améliorant ainsi l’engagement et la visibilité des biens immobiliers.' },
    { question: 'Combien coûte une tournée 3D Matterport ?', answer: 'Le coût d’une tournée 3D Matterport varie en fonction de la taille de la propriété et des services supplémentaires requis. Contactez-nous pour obtenir un devis personnalisé.' },
    { question: 'Qu’est-ce qu’un scan 3D d’une propriété ?', answer: 'Un scan 3D d’une propriété utilise la technologie de capture d’images pour créer une réplique virtuelle précise et interactive de l’espace physique.' },
  ];

  const [formData, setFormData] = useState({ email: '', question: '' });
  const [showThankYou, setShowThankYou] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_xelok0a', 'template_7cud6pd', formData, 'qoVtexfIDNNBno05K')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setShowThankYou(true);
      }, (error) => {
        console.log('FAILED...', error);
        alert('Une erreur est survenue, veuillez réessayer.');
      });
  };

  return (
    <div className="faq-container">
      <div className="faq-section">
        <h2>Questions fréquentes</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        <div className="faq-form">
          <h3>Avez-vous trouvé réponse à vos questions?</h3>
          <div className="faq-form-options">
            <label>
              <input type="radio" name="found-answer" value="yes" /> Oui
            </label>
            <label>
              <input type="radio" name="found-answer" value="no" /> Non
            </label>
          </div>
          {!showThankYou ? (
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Votre courriel"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <textarea
                name="question"
                placeholder="Posez votre question..."
                value={formData.question}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit" className="submit-btn">Envoyer</button>
            </form>
          ) : (
            <p>Merci pour votre question, nous vous recontacterons au plus vite.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
