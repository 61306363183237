import React, { useState } from 'react';
import { camera3dLandingPage } from '../../img/index';
import emailjs from 'emailjs-com';

const VirtualTourSection = () => {
  const [email, setEmail] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_xelok0a', 'template_qw0vy5p', { user_email: email }, 'qoVtexfIDNNBno05K')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setShowThankYou(true);
      }, (error) => {
        console.log('FAILED...', error);
        alert('Une erreur est survenue, veuillez réessayer.');
      });
  };

  return (
    <div className="virtual-tour-section">
      <div className="content">
        <h1>VISITE <br/> VIRTUELLE <span className="highlight-3d">3D</span></h1>
        <p>Augmentez la <span className="highlight">VISIBILITÉ</span> de votre bien d’une façon <br/> innovante, disponible 24/7</p>
        <div className="line"></div>
        <div className="estimate-form">
          <label>↓ Estimation rapide en ligne ↓</label>
          {!showThankYou ? (
            <form onSubmit={handleSubmit} className="input-group">
              <input 
                type="email" 
                placeholder="Adresse courriel" 
                value={email} 
                onChange={handleChange} 
                required 
              />
              <button type="submit">Estimer Maintenant !</button>
            </form>
          ) : (
            <p>Merci d'avoir laissé votre courriel, nous allons vous recontacter au plus vite.</p>
          )}
        </div>
      </div>
      <div className="image-container">
        <img src={camera3dLandingPage} alt="Camera" />
      </div>
    </div>
  );
};

export default VirtualTourSection;
