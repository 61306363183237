import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faCamera,
  faCogs,
  faTruck,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { MultiStepForm } from './indexSection'; // Assurez-vous d'importer le composant du formulaire

const Step = ({ icon, title, description }) => {
  return (
    <div className="step">
      <div className="icon">
        <FontAwesomeIcon icon={icon} size="3x" />
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const FourStepsSection = () => {
  const [showForm, setShowForm] = useState(
    window.location.hash === '#soumission-rapide'
  );

  useEffect(() => {
    if (window.location.hash === '#soumission-rapide') {
      setShowForm(true);
    }
  }, []);

  const handleStartClick = () => {
    setShowForm(true);
    window.location.hash = 'soumission-rapide';
  };

  return (
    <div className="four-steps-section" id="soumission-rapide">
      <h2>
        Votre visite virtuelle en <span className="highlight">4 étapes</span>
      </h2>
      <p>
        Créer une visite virtuelle est plus simple que vous le croyez, voyez-le
        par vous-même
      </p>
      <div className="steps-container">
        <Step
          icon={faClipboardList}
          title="Étape 1"
          description="Analyse de vos besoins et soumission"
        />
        <FontAwesomeIcon icon={faChevronRight} size="2x" className="arrow" />
        <Step
          icon={faCamera}
          title="Étape 2"
          description="Scan de votre espace"
        />
        <FontAwesomeIcon icon={faChevronRight} size="2x" className="arrow" />
        <Step
          icon={faCogs}
          title="Étape 3"
          description="Création de votre visite virtuelle"
        />
        <FontAwesomeIcon icon={faChevronRight} size="2x" className="arrow" />
        <Step
          icon={faTruck}
          title="Étape 4"
          description="Livraison et implémentation de votre scan en 48h"
        />
      </div>
      {!showForm && (
        <button className="start-button" onClick={handleStartClick}>
          Estimer maintenant !
        </button>
      )}
      {showForm && <MultiStepForm />}
    </div>
  );
};

export default FourStepsSection;
