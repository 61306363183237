import React from 'react';

const StepFour = ({ formData, handleChange }) => {
  return (
    <div className="step-four">
      <h2>Informations de contact</h2>
      <div className="input-group">
        <label>
          Nom complet
          <input
            type="text"
            value={formData.nom}
            onChange={(e) => handleChange('nom', e.target.value)}
            placeholder='Prénom, Nom'
            required
          />
        </label>
      </div>
      <div className="input-group">
        <label>
          Numéro de téléphone
          <input
            type="text"
            value={formData.telephone}
            onChange={(e) => handleChange('telephone', e.target.value)}
            placeholder='Ex: (000) 736-4263'
            required
          />
        </label>
      </div>
      <div className="input-group">
        <label>
          Adresse e-mail
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            placeholder='Ex:xyz@gmail.com'
            required
          />
        </label>
      </div>
    </div>
  );
};

export default StepFour;
