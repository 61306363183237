import React from 'react';

const StepOne = ({ formData, handleChange }) => {
  const handleScansChange = (e) => {
    const scans = e.target.value;
    const piedsCarres = scans ? Array(parseInt(scans, 10)).fill('') : [];
    handleChange('scans', scans);
    handleChange('piedsCarres', piedsCarres);
  };

  return (
    <div className="step-one">
      <h2>Soumission Rapide</h2>
      <label>Combien de scan avez-vous besoin ?</label>
      <div className="input-group">
        <input
          type="number"
          value={formData.scans}
          onChange={handleScansChange}
          min="1"
          max="20"
        />
      </div>
    </div>
  );
};

export default StepOne;
