import React, { useState, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import Summary from './Summary';
import emailjs from 'emailjs-com';

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    scans: 1,
    piedsCarres: [''],
    tags: false,
    numberOfTags: 0,
    nom: '',
    telephone: '',
    email: '',
    prixTotal: 0,
  });
  const [loading, setLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [contactUsMessage, setContactUsMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;
      let totalPiedsCarres = 0;

      formData.piedsCarres.forEach((piedCarre) => {
        totalPiedsCarres += piedCarre;
        if (piedCarre < 1000) {
          total += 440;
        } else {
          const extra = piedCarre - 1000;
          const rate = Math.max(0.275 - extra * 0.00000729, 0.1); // Tarif minimal de 0.1$
          total += 440 + rate * extra;
        }
      });

      if (totalPiedsCarres > 10000) {
        setContactUsMessage(true);
      } else {
        setContactUsMessage(false);
      }

      if (formData.tags) {
        total += formData.numberOfTags * 10;
      }
      // Arrondir le total à deux décimales
      total = Math.round(total * 100) / 100;
      return total;
    };

    const prixTotal = calculateTotal();
    setFormData((prevFormData) => ({
      ...prevFormData,
      prixTotal,
    }));
  }, [formData.piedsCarres, formData.tags, formData.numberOfTags]);

  const handleNext = () => {
    if (
      currentStep === 1 &&
      (formData.scans === '' || parseInt(formData.scans, 10) < 1)
    ) {
      alert('Veuillez entrer un nombre de scans supérieur à 0');
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.nom || !formData.telephone || !formData.email) {
      setErrorMessage('Veuillez remplir tous les champs.');
      return;
    }
    if (!validateEmail(formData.email)) {
      setErrorMessage('Veuillez entrer une adresse email valide.');
      return;
    }

    setErrorMessage('');
    setLoading(true);

    // Concaténer les informations de piedsCarres et tags
    const piedsCarresText = formData.piedsCarres
      .map((piedCarre, index) => `Scan ${index + 1}: ${piedCarre} pieds carrés`)
      .join(', ');
    const tagsText = formData.tags
      ? `Oui, Nombre de tags: ${formData.numberOfTags}`
      : 'Non';

    const emailData = {
      prenom: formData.prenom,
      nom: formData.nom,
      email: formData.email,
      telephone: formData.telephone,
      scans: formData.scans,
      piedsCarresText,
      tagsText,
      prixTotal: formData.prixTotal,
    };

    emailjs
      .send(
        'service_xelok0a',
        'template_b7ikp3t',
        emailData,
        'qoVtexfIDNNBno05K'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setLoading(false);
          setShowThankYou(true);
        },
        (error) => {
          console.log('FAILED...', error);
          setLoading(false);
          alert('Une erreur est survenue, veuillez réessayer.');
        }
      );
  };

  const handleNewSubmission = () => {
    setFormData({
      scans: 1,
      piedsCarres: [''],
      tags: false,
      numberOfTags: 0,
      nom: '',
      telephone: '',
      email: '',
      prixTotal: 0,
    });
    setShowThankYou(false);
    setCurrentStep(1);
  };

  return (
    <div className="MultiStepForm">
      {showThankYou ? (
        <div className="thank-you-message">
          <h1>
            <span>Merci</span> pour votre soumission !
          </h1>
          <p>Nous vous recontacterons au plus vite.</p>
          <p>
            - Équipe <a href="https://tecnova.ca">Tecnova</a>
          </p>
          <button onClick={handleNewSubmission}>Nouvelle soumission</button>
        </div>
      ) : (
        <div className="multi-step-form">
          {currentStep === 1 && (
            <StepOne formData={formData} handleChange={handleChange} />
          )}
          {currentStep === 2 && (
            <StepTwo formData={formData} handleChange={handleChange} />
          )}
          {currentStep === 3 && (
            <StepThree formData={formData} handleChange={handleChange} />
          )}
          {currentStep === 4 && (
            <Summary formData={formData} handleSubmit={handleSubmit} />
          )}
          {currentStep === 5 && (
            <StepFour formData={formData} handleChange={handleChange} />
          )}

          {contactUsMessage && (
            <div className="contact-us-message">
              <p>
                Pour les superficies de plus de 25,000 pieds carrés, veuillez
                nous contacter pour obtenir un devis personnalisé.
              </p>
            </div>
          )}

          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}

          <div className="navigation-buttons">
            {currentStep > 1 && <button onClick={handleBack}>Précédent</button>}
            {currentStep < 5 && <button onClick={handleNext}>Suivant</button>}
            {currentStep === 5 && (
              <button onClick={handleSubmit}>Envoyer</button>
            )}
          </div>
          {loading && (
            <div className="loading">Envoie de votre soumission...</div>
          )}
          {showThankYou && (
            <p>
              Merci pour votre soumission, nous vous recontacterons au plus
              vite.
            </p>
          )}
          <p className="annonce">
            Le prix de cette soumission est approximatif et peut être sujet à
            modification
          </p>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
