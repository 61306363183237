import React from 'react';
import { NavBar, Footer, TitleCenterUnderline, Button } from '../components/indexComponent';
import '../CSS/NotFoundPage.css';

const NotFoundPage = () => {
  return (
    <div className="not-found-page-body">
      <NavBar />
      <div className="not-found-page hidden">
        <TitleCenterUnderline title={'404 - Page Non Trouvée'} />
        <p>La page que vous recherchez n'existe pas.</p>
        <Button route={'/'} text={"Retourner à la page d'accueil"} />
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
