import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function WorkSection({id, companyName, iframeTitle, matterportLink, videoLink, posterImg, websiteLink, typeOfWork, description, reverse, typeOfWork2, description2, isMoreScan, otherScansLinks, thumbnail}) {

    /* Traduction */
    const { i18n, t } = useTranslation();

    const [showScan, setShowScan] = useState(false);

    const scrollToElement = (elementId) => {
        setTimeout(() => {
            const element = document.getElementById(elementId);
            if (element) {
                const rect = element.getBoundingClientRect();
                const offsetTop = rect.top + window.pageYOffset;
                window.scrollTo({
                    top: offsetTop - 80, // défilement de 80px plus haut
                    behavior: 'smooth'
                });
            }
        }, 0);
    };

    /*   video   */
      // Références pour les vidéos
  const videoRefs = useRef([]);

  // Ajouter une vidéo à la référence
  const addVideoRef = (el) => {
    if (el && !videoRefs.current.includes(el)) {
      videoRefs.current.push(el);
    }
  };

  // Fonctions pour jouer et mettre en pause la vidéo
  const playVideo = (video) => {
    video.play();
  };

  const pauseVideo = (video) => {
    video.pause();
  };

  // Effet pour ajouter et nettoyer les événements
  useEffect(() => {
    const handleMouseEnter = (e) => playVideo(e.target);
    const handleMouseLeave = (e) => pauseVideo(e.target);
    const handleTouchStart = (e) => {
      playVideo(e.target);
      e.target.setAttribute('controls', 'true');
    };
    const handleTouchEnd = (e) => {
      pauseVideo(e.target);
      e.target.removeAttribute('controls');
    };

    videoRefs.current.forEach((video) => {
      video.addEventListener('mouseenter', handleMouseEnter);
      video.addEventListener('mouseleave', handleMouseLeave);
      video.addEventListener('touchstart', handleTouchStart);
      video.addEventListener('touchend', handleTouchEnd);
    });

    // Nettoyage des événements
    return () => {
      videoRefs.current.forEach((video) => {
        video.removeEventListener('mouseenter', handleMouseEnter);
        video.removeEventListener('mouseleave', handleMouseLeave);
        video.removeEventListener('touchstart', handleTouchStart);
        video.removeEventListener('touchend', handleTouchEnd);
      });
    };
  }, []);

  return (
    <section class="recentWork__virtualTour" id={`${id}`}>
        <div class="recentWork__virtualTour--title">
            <Link to={'/'} onClick={() => scrollToElement('recentWork')}>
            <div className="backArrow">
                <FontAwesomeIcon icon={faArrowLeft} />
                <p>{t('workPortfolio:backToRecentWork')}</p>
            </div>
            </Link>
            <h2>{companyName}</h2>
        </div>

        <div class="virtualTour">
            <div class="virtualTour__content">

                {reverse === true ? 
                <>
                <div class="virtualTour__content--text">
                    <h3>{typeOfWork}</h3>
                    <p>{description}</p>
                </div>


                {matterportLink ? 
                    <div class="virtualTour__content--work">
                        {showScan ? 
                            <iframe class="captur3d-3d-tour__iframe"
                            title={iframeTitle}
                            width="100%"
                            height="480"
                            src={matterportLink}
                            frameborder="0"
                            allowfullscreen
                            mozallowfullscreen="true"
                            webkitallowfullscreen="true"
                            onmousewheel=""
                            allow='xr-spatial-tracking'>
                            </iframe>
                        :
                            <div 
                                className='beforeScanScreen' 
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${thumbnail})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <button onClick={() => {setShowScan(true)}}>{t('workPortfolio:voirVisite')}</button>
                            </div>
                        }

                    </div>
                : videoLink ? 
                    <div class={`virtualTour__content--work videoContainer ${i18n.language === 'en' ? '' : 'Fr'}`}>
                        <a href={websiteLink}>
                        <video
                            ref={addVideoRef} // Ajouter la référence ici
                            className="videoDemo"
                            poster={posterImg}
                            preload="none"
                            loop
                            muted
                        >
                            <source src={videoLink} type="video/mp4" />
                        </video>
                        </a>
                    </div>
                :
                    ''
                }

                </>
                :
                <>
                {matterportLink ? 
                    <div class="virtualTour__content--work">
                        {showScan ? 
                            <iframe class="captur3d-3d-tour__iframe"
                            title={iframeTitle}
                            width="100%"
                            height="480"
                            src={matterportLink}
                            frameborder="0"
                            allowfullscreen
                            mozallowfullscreen="true"
                            webkitallowfullscreen="true"
                            onmousewheel=""
                            allow='xr-spatial-tracking'>
                            </iframe>
                        :
                            <div 
                                className='beforeScanScreen' 
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${thumbnail})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <button onClick={() => {setShowScan(true)}}>{t('workPortfolio:voirVisite')}</button>
                            </div>
                        }
                    </div>
                : videoLink ? 
                    <div class={`virtualTour__content--work videoContainer ${i18n.language === 'en' ? '' : 'Fr'}`}>
                        <a href={websiteLink}>
                        <video
                            ref={addVideoRef} // Ajouter la référence ici
                            className="videoDemo"
                            poster={posterImg}
                            preload="none"
                            loop
                            muted
                        >
                            <source src={videoLink} type="video/mp4" />
                        </video>
                        </a>
                    </div>
                :
                    ''
                }

                <div class="virtualTour__content--text">
                    <h3>{typeOfWork}</h3>
                    <p>{description}</p>
                </div>
                </>
                }

                {matterportLink && videoLink ?
                    <div class="virtualTour">
                        <div class="virtualTour__content">
        
                            <div class={`virtualTour__content--work videoContainer ${i18n.language === 'en' ? '' : 'Fr'}`}>
                                <a href={websiteLink}>
                                <video
                                    ref={addVideoRef} // Ajouter la référence ici
                                    className="videoDemo"
                                    poster={posterImg}
                                    preload="none"
                                    loop
                                    muted
                                >
                                    <source src={videoLink} type="video/mp4" />
                                </video>
                                </a>
                            </div>
        
                            <div class="virtualTour__content--text">
                                <h3>{typeOfWork2}</h3>
                                <p>{description2}</p>
                            </div>
                        </div>
                    </div>
                    : 
                    ''
                }

                {isMoreScan ? 
                    <>
                        {showScan ? 
                            <div class="virtualTour__content justify-sb">
                                <div class="virtualTour__content--work">
                                    <iframe class="captur3d-3d-tour__iframe"
                                    title='Virtual Tour'
                                    width="100%"
                                    height="480"
                                    src={otherScansLinks[0]}
                                    frameborder="0"
                                    allowfullscreen
                                    mozallowfullscreen="true"
                                    webkitallowfullscreen="true"
                                    onmousewheel=""
                                    allow='xr-spatial-tracking'>
                                    </iframe>
                                </div>
                                <div class="virtualTour__content--work">
                                    <iframe class="captur3d-3d-tour__iframe"
                                    title='Virtual Tour'
                                    width="100%"
                                    height="480"
                                    src={otherScansLinks[1]}
                                    frameborder="0"
                                    allowfullscreen
                                    mozallowfullscreen="true"
                                    webkitallowfullscreen="true"
                                    onmousewheel=""
                                    allow='xr-spatial-tracking'>
                                    </iframe>
                                </div>
                            </div>
                        :
                            ""
                        }
                     </>
                    :
                    ''
                }
            </div>
        </div>
    </section>
  )
}

export default WorkSection