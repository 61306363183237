import React, { useState, useEffect, useRef } from 'react';
import { exempleTag } from '../../../img/index';

const StepThree = ({ formData, handleChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);

  return (
    <div className="step-three">
      <h2>Question 3</h2>
      <label>
        Souhaitez-vous ajouter des tags informatifs ? <br/>
        <span className="info-link" onClick={toggleTooltip}>Qu'est-ce qu'un tag ?</span>
        {showTooltip && (
          <div className="tooltip" ref={tooltipRef}>
            <p>
              Un tag est un point d'intérêt cliquable intégré dans une visite virtuelle 3D. 
              Il permet d'ajouter des informations supplémentaires, comme du texte, des images, 
              des vidéos ou des liens, directement sur des objets ou des zones spécifiques dans la visite. 
              Cela permet aux utilisateurs d'en apprendre davantage sur les éléments importants 
              sans quitter l'expérience immersive.
            </p>
            <img src={exempleTag} alt="Exemple d'utilisation des tags" />
          </div>
        )}
      </label>
      <div className="button-group">
        <button
          type="button"
          className={formData.tags ? 'selected' : ''}
          onClick={() => handleChange('tags', true)}
        >
          Oui
        </button>
        <button
          type="button"
          className={!formData.tags ? 'selected' : ''}
          onClick={() => handleChange('tags', false)}
        >
          Non
        </button>
      </div>
      {formData.tags && (
        <div className="tags-input input-group">
          <label>Combien de tags ?</label>
          <input
            type="number"
            value={formData.numberOfTags}
            onChange={(e) => handleChange('numberOfTags', parseInt(e.target.value, 10) || 0)}
          />
        </div>
      )}
    </div>
  );
};

export default StepThree;
