import React, { useEffect } from 'react';
import '../App.css';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { NavBar, Footer, Animation, BannerWord, CookieConsent } from '../components/indexComponent';
import {
  Accueil,
  Services,
  RecentWork,
  Team,
  OurMission,
  ContactUs,
} from '../components/sections/indexSection';

function App() {
  /* Traduction */
  const { t, i18n } = useTranslation();

  $(window).scroll(() => {
    if ($(window).scrollTop() > 0) {
      $('.indexMenu').addClass('navbar-scrolled');
    } else {
      $('.indexMenu').removeClass('navbar-scrolled');
    }
  });

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      localStorage.setItem('userLanguage', lng);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  return (
    <div>
      <Helmet>
        <title>Tecnova : Innovons, Créons et Optimisons Votre Présence En Ligne</title>
        <meta
          name="description"
          content="Tecnova, votre partenaire de confiance pour la conception de sites web, la numérisation 3D et la cybersécurité au Québec. Découvrez nos services!"
        />

        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-HX3G7F6213"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-HX3G7F6213');
          `}
        </script>
      </Helmet>
      <Animation />
      <NavBar />

      <Accueil />

      <BannerWord />

      <Services />

      <RecentWork />

      <Team />

      <OurMission />

      <ContactUs />

      <Footer />
    </div>
  );
}

export default App;
