import React from 'react';

const Summary = ({ formData, handleSubmit }) => {
  return (
    <div className="summary">
      <h2>Résumé de votre soumission</h2>
      <p>Nombre de scans : {formData.scans}</p>
      {formData.piedsCarres.map((piedCarre, index) => (
        <p key={index}>Espace {index + 1} : {piedCarre} pieds carrés</p>
      ))}
      <p>Tags informatifs : {formData.tags ? 'Oui' : 'Non'}</p>
      {formData.tags && <p>Nombre de tags : {formData.numberOfTags}</p>}
      <p>Prix total : {formData.prixTotal} $</p>
    </div>
  );
};

export default Summary;
