import React, { useState } from 'react';
import { VirtualTourSection, NavBar, Footer } from '../components/indexComponent';
import {
  StatisticsSection,
  MultiStepForm,
  DemoSection,
  PlansSection,
  MatterportSection,
  FAQSection,
  EstimationForm,
  FourStepsSection,
} from '../components/sections/indexSection';
import '../CSS/VirtualTourLandingPage.css';
import { useTranslation } from 'react-i18next';
import RecentWork from '../components/sections/RecentWork';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function VirtualTourLandingPage() {
  const [showWork, setShowWork] = useState(false);

  /* Traduction */
  const { t, i18n } = useTranslation();

  const scrollToElement = (elementId) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  return (
    <div className="VirtualTourLandingPage">
      <Helmet>
        <title>Créez Votre Visite Virtuelle en 4 Étapes Simples avec Tecnova</title>
        <meta
          name="description"
          content="Optimisez votre stratégie marketing avec nos visites virtuelles 3D. Réduisez les coûts, améliorez la confiance et accélérez les décisions de vos clients!"
        />
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '446195068130569');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=446195068130569&ev=PageView&noscript=1"
          />
          `}
        </noscript>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-HX3G7F6213"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-HX3G7F6213');
          `}
        </script>
      </Helmet>
      <NavBar isWhite={true} />
      <VirtualTourSection />
      {/* <PlansSection/> */}
      <FourStepsSection />
      <MatterportSection />
      <DemoSection />
      <FAQSection />
      {/* <EstimationForm /> */}
      <section className="section cta">
        <h2>Adoptez la Révolution de la Visite Virtuelle</h2>
        <div className="button-container">
          <button
            onClick={() => {
              setShowWork(!showWork);
            }}
          >
            {' '}
            Nos Réalisations <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button onClick={() => scrollToElement('soumission-rapide')}>
            Débuter Mon Projet <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </div>

        {showWork ? <RecentWork type={'virtualTour'} animation={false} /> : ''}
      </section>
      <Footer />
    </div>
  );
}

export default VirtualTourLandingPage;
