import React from 'react';

const StepTwo = ({ formData, handleChange }) => {
  const handlePiedsCarresChange = (index, value) => {
    const newPiedsCarres = [...formData.piedsCarres];
    newPiedsCarres[index] = parseInt(value, 10) || '';
    handleChange('piedsCarres', newPiedsCarres);
  };

  return (
    <div className="step-two">
      <h2>Question 2</h2>
      <label>Quel est le nombre de pieds carrés de chaque espace ?</label>
      <div className="input-group">
        {formData.piedsCarres.length > 0 ? (
          formData.piedsCarres.map((piedCarre, index) => (
            <div className="d-flex gap-1 align-center">
              <p>Scan {index + 1} :</p>
              <input
                key={index}
                type="number"
                value={piedCarre}
                placeholder={`Nombre de pieds carrés`}
                onChange={(e) => handlePiedsCarresChange(index, e.target.value)}
                min="1"
              />
            </div>
          ))
        ) : (
          <p>Aucune case à afficher</p>
        )}
      </div>
    </div>
  );
};

export default StepTwo;
