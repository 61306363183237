import React, { useState } from 'react';

function CookieConsent({
  text,
  buttonText,
  textRefuseBtn,
  textCookieLink,
  seeCookiePrivacyFonction,
}) {
  const [isShow, setIsShow] = useState(() => {
    // On vérifie si le consentement a déjà été donné
    return localStorage.getItem('cookieConsentGiven') !== 'true';
  });

  const handleClose = () => {
    setIsShow(false);
    localStorage.setItem('cookieConsentGiven', 'true'); // Enregistre le consentement
  };

  const handleRefuse = () => {
    setIsShow(false);
    localStorage.clear();
  };

  return (
    <div className={`cookie-banner ${isShow ? '' : 'close'}`}>
      <p>
        {text} <a onClick={seeCookiePrivacyFonction}>{textCookieLink}</a>
      </p>
      <div className="btn-container">
        <button onClick={handleRefuse}>{textRefuseBtn}</button>
        <button onClick={handleClose}>{buttonText}</button>
      </div>
    </div>
  );
}

export default CookieConsent;
