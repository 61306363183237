import React from 'react'

function StatisticsSection() {
  return (
    <div className="statistics-section">
    <div className="statistics-background">STATISTIQUES</div>
    <div className="statistics-content">
      <div className="stat">
        <p><strong>+25%</strong></p>
        <span>Engagement en ligne</span>
      </div>
      <div className="stat">
        <p><strong>+63%</strong></p>
        <span>Chances d'acheter une maison</span>
      </div>
      <div className="stat">
        <p><strong>+14%</strong></p>
        <span>Réservation et vente</span>
      </div>
      <div className="stat">
        <p><strong>-50%</strong></p>
        <span>Temps et coûts de déplacement</span>
      </div>
    </div>
  </div>
  )
}

export default StatisticsSection