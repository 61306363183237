import React, { useState } from 'react';
import {camera3dSide} from '../../img/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const MatterportSection = () => {
  const [view, setView] = useState('avantages');

  const avantages = [
    "Possible de visiter à distance, donc diminution du temps et des coûts",
    "Amélioration de l’image de marque",
    "Amélioration du lien de confiance avec votre client",
    "Peut être utilisé durant des rénovations avec les mesures",
    "Accélère la vente et la décision du client dû au fait que la visite est 24/7 disponible",
    "Outil marketing incroyable",
    "Avantage concurrentiel",
  ];

  const caracteristiques = [
    // Ajoutez des caractéristiques ici
    "Visite virtuelle de haute qualité 4K",
    "Implémentable sur n'importe quelle plateforme, même sur Google map!",
    "Vue d'ensemble et du dessus de la propriété",
    "Toute les mesures de l'endroit",
    "Livraison en 48 heures et moins",
    "Tag informatif",
    "Propulsé par l'intelligence artificielle",
    "Disponibilité 24/7 de la visite",
  ];

  const itemsToDisplay = view === 'avantages' ? avantages : caracteristiques;

  return (
    <div className="matterport-section">
      <div className="matterport-image">
        <img src={camera3dSide} alt="Matterport Camera" />
      </div>
      <div className="matterport-content">
        <h2>Qu’est-ce qu’offre la visite virtuelle Matterport ?</h2>
        <div className="button-group">
          <button 
            className={view === 'avantages' ? 'active' : ''} 
            onClick={() => setView('avantages')}
          >
            Avantages
          </button>
          <span className="double-fleche"></span>
          <button 
            className={view === 'caracteristiques' ? 'active' : ''} 
            onClick={() => setView('caracteristiques')}
          >
            Caractéristiques
          </button>
        </div>
        <ul>
          {itemsToDisplay.map((item, index) => (
            <li key={index}><FontAwesomeIcon icon={faChevronRight} />{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MatterportSection;
