import React from 'react';
import {modeleScan3d} from '../../img/index';
import { Link } from 'react-router-dom';

const DemoSection = () => {
  return (
    <div className="demo-section">
        <div className="content">
            <div className="left">
                <h2>Découvrez et testez la puissance <br/> des visites virtuelle 3D !</h2>
                <button><Link to="/realisations">Voir démo</Link></button>
            </div>
            <div className="image-container">
                <img src={modeleScan3d} alt="Virtual Tour" />
            </div>
        </div>
    </div>
  );
};

export default DemoSection;
